
body { color:#333; font-family: "ë‚˜ëˆ”ê³ ë”•", NanumGothic, sans-serif;letter-spacing: -0.05em;}

.t-a-c {text-align:center;}
.flo-l {float:left;}
.ov-hi {overflow:hidden;}
p {font-size: 1.25rem;color: #ffffff;line-height: 30px;}
.bold {font-weight:600;}

#mainMenu nav > ul > li > a {font-size: 16px;}

.inspiro-slider .slide .slide-captions h1 {font-size: 70px;}
.header-extras img {width: 20px;margin-left: 5px;}
.dark .header-extras li span {color:#fff;} 
.header-extras li span {color:#333;} 

h2.tit-v00 {font-size:36px; font-weight:700;color:#333;}
h2.tit-v01 {font-size:36px; font-weight:700;color:#159aca;}
h2.tit-v02 {font-size:36px; font-weight:700;color:#fe8521;}
h2.tit-v03 {font-size:36px; font-weight:700;color:#734da2;}


.btn.btn-v01 {border:1px solid #7D6CFF !important; background:#7D6CFF !important; color:#fff;font-weight:600;padding: 9px 36px;box-shadow: -2px -2px 4px rgba(255, 255, 255, 0.25) inset;}
.btn.btn-v01:hover {border:1px solid #ffffff !important;}
.btn.btn-v02 {border:1px solid transparent !important; background:#ffffff !important; color:#03000A;font-weight:600;padding: 9px 36px;box-shadow: -2px -2px 4px rgba(3, 0, 10, 0.25) inset; }
.btn.btn-v02:hover {border:1px solid #ffffff !important; }
.btn.btn-v03 {border:1px solid #734da2 !important; background:#734da2 !important; color:#fff;font-weight:600;padding: 10px 50px ;}
.btn.btn-v04 {border:2px solid #734da2 !important; background:#CE041C !important; color:#fff;font-weight:600;padding: 10px 50px ;}
.no-padding{padding: 0 !important;}

.lead {line-height:30px;}
.img-loaded {opacity: 0.3;}


.plan-list ul li em {border: 1px solid #ddd;padding: 5px 10px;font-style: normal;border-radius: 5px;margin-right: 8px;font-weight:600;background:#ddd;}
.plan-list ul li {list-style: none;font-style: normal;line-height: 30px;font-size:18px;margin-bottom:10px;}


.w-100p {width:100%;}
.w-50p {width:50%;}

.expense div {font-size: 24px;line-height: 60px;}

.expense p {font-size: 24px;border:1px solid #fff;border-radius: 10px;text-align: center;padding: 10px;margin-bottom:0px;}

.bg-f8 {background:#f8f8f8;}
.invest h2 {font-size:30px;font-weight:600;}
.invest a {font-size: 20px !important;padding: 25px 40px !important;}

.fon-col-fe {color:#fe8521;}
.fon-col-00A {color:#00A2E8 !important;}
.bg-00A {background:#00A2E8;}
.bg-fe {background:#fe8521;}
.bg-f6 {background:#F6F6F6;}


.confirmed p {font-size:22px;color: #ED1C24;font-weight: 600;}
.box-01 span {background:#fe8521 !important;padding: 10px 30px;border-radius: 10px;color: #fff;font-weight: 600;margin-right:10px;}
.box-02 span {background:#00A2E8 !important;padding: 10px 30px;border-radius: 10px;color: #fff;font-weight: 600;margin-right:10px;}


.text-col-000 {color:#000 !important;}
.w-270p {width:270px;}

.fa-caret-square-down {color:#00A2E8;}
.fa-caret-square-up {color:#ED1C24;}

.p-t-20 {padding-top:20px;}
.m-t-40 {margin-top: 40px;}
.m-t-0 {margin-top:0px !important;}
.m-b-15 {margin-bottom:15px;} 


button.btn.btn-warning, .btn:not(.close):not(.mfp-close).btn-warning, a.btn:not([href]):not([tabindex]).btn-warning {
background: rgb(255, 116, 44);}


/* ì½˜í…ì¸  1 */

.agree_cont {margin: 25px 0px;padding: 30px 40px;border: 1px solid rgb(221, 221, 221);background: rgb(246, 246, 246);}
.agree_cont li {font-size: 20px;line-height: 40px;word-break: break-all;}
.sub-tit p {margin-bottom:0px;font-size:20px}


.custom-control .custom-control-label {font-size:16px !important;font-weight:600 !important;color:#ffffff !important;}
.sub-page .btn-danger {padding: 20px 80px !important;font-size: 18px !important;}


/* ë³¸ì¸ì¸ì¦ */

.t01 .num {display: inline-block;width: 50px;font-weight: 700;line-height: 50px;height: 50px;margin-right: 10px;font-size: 30px;color: rgb(255, 255, 255);
    text-align: center;border-radius: 50%;background: rgb(255, 116, 44);}

.line-block {border-bottom:1px solid #ddd; padding-bottom:30px; margin-bottom:30px;}
.t01 span {font-size: 33px;color: rgb(34, 34, 34);font-weight: 700;line-height: 50px;letter-spacing: -0.09em;}
.t02 {font-size: 26px;color: rgb(34, 34, 34);line-height: 1.38;margin-top: 25px;letter-spacing: -0.05em;}
 p.t03 {margin-top:15px;letter-spacing: -0.05em;}


.img-box {position: relative;}
.tbox {position: absolute;top: 25px;font-size: 19px;left:20px;}
.img-box img {width: 100%;height: 100%;object-fit: cover;}



.box-1 {border:1px solid #999;padding:20px;}


p.t01 {font-weight:600;color:#000;}
.text-05 {margin-top: 0px;padding: 9px 25px 10px;margin-bottom:20px;font-size: 18px;line-height:30px !important;color: rgb(51, 51, 51);line-height: 1.4;border-radius: 9px;background: rgb(246, 246, 246);}



/* ê³ ê°ì§€ì› */
.inquire .form-group {margin-bottom:5px; padding-bottom:5px;border-bottom:1px solid #ddd;}
.inquire label {background: rgb(246, 246, 246);margin-bottom:0px !important;line-height: 30px;font-size: 14px !important;
    font-weight: 600 !important;}
.inquire label em {color: rgb(255, 116, 44);}

.inquire .type3 {width: 227px;display: inline-block;}
.inquire .descript {display: inline-block;margin-left: 10px;font-size: 15px;color: rgb(102, 102, 102);}


.inquire .kakao {color: rgb(255, 255, 255);background: rgb(255, 116, 44);min-width: 360px;height: 60px;font-size: 22px;line-height: 60px;display: inline-block;border: none;border-radius: 5px;
margin-bottom:10px;}


.title-block {position: relative;padding-left: 27px;margin: 35px 0px 15px;font-size: 22px;color: rgb(51, 51, 51);font-family: NotoSansCJKkrBold;}
.title-block::before {position: absolute;top: 7px;left: 0px;width: 16px;height: 16px;border-radius: 6px;border: 5px solid rgb(255, 116, 44);content: "";}


#footer {border-top:1px solid #ddd; }
#footer .copyright-content {background:#fff;}
.copyright-content span {margin:0 8px;}

.che-inq label {line-height: 22px;}
.che-inq .bottom-agree {margin-left:5px;}


@media (max-width: 768px) {
	.container {padding: 0 0px;}
	img {max-width: 100%;}

	.confirmed .text-center {text-align:left !important;}
	.inspiro-slider .slide .slide-captions h1 {font-size: 40px;line-height: 50px;}
}

@media (max-width: 499px) {
	/* #header #logo > a img {width:250px;} */
	.container {padding: 0 15px;}
	h2.tit-v01, h2.tit-v02, h2.tit-v03 {font-size: 24px;}
	.heading-text.heading-section h2, .heading-text h2, h2.tit-v00, .invest h2 {font-size: 24px; font-weight:600;}
	.confirmed p, .expense p, .expense div {font-size: 18px;}
	.w-270p {width:250px;}
	.w-100p-499 {width:100% !important;text-align:left !important;}

	.t01 span {font-size: 24px;line-height: 30px;}
	.t01 .num {width: 40px;line-height: 40px;height: 40px;font-size: 24px;}
	.t02 {font-size: 20px;line-height: 1.38;}
	.tbox {top: 12px;font-size: 16px;}


	.dark .header-extras li span, .header-extras li span {display:none;}
	.custom-control.custom-checkbox {margin-bottom:15px;}
	.container .row h2 {padding-left:15px;}
}
